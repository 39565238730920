<template>
  <div>
    <background></background>
    <notification-bar></notification-bar>
    <div class="layout-horizontal layout-inflexible">
      <main-menu :pick-up.sync="pickUp" :current-tag.sync="currentTag" icon="fas fa-toolbox" title="内容管理">
        <main-menu-item title="问卷配置" icon="fas fa-clipboard" tag="questionnaire-setting"
                        @click.native="handleQuestionnaireMenuClick"></main-menu-item>
        <main-menu-item title="打卡配置" icon="fas fa-clock" tag="punch-setting"
                        @click.native="handlePunchMenuClick"></main-menu-item>
        <main-menu-item title="处方模板" icon="fas fa-prescription-bottle" tag="prescription-template"></main-menu-item>
        <main-menu-item title="食谱管理" icon="fas fa-utensils" tag="cookery-book"
                        @click.native="handleCookeryBookMenuClick"></main-menu-item>
        <main-menu-item title="报告模板" icon="fas fa-file-medical" tag="report-template"
                        @click.native="handleReportMenuClick"></main-menu-item>
        <main-menu-item title="干预方案" icon="fas fa-seedling" tag="scheme-setting"
                        @click.native="handleSchemeMenuClick"></main-menu-item>
        <main-menu-item title="系统管理" icon="fas fa-cogs" tag="system-setting"
                        @click.native="handleSystemSettingMenuClick"
                        v-if="$isMgr"
        ></main-menu-item>
        <div class="divider margin-vertical"></div>
        <main-menu-item title="日常管理" icon="fas fa-users" tag="daily-management"
                        @click.native="handleDailyManagementClick"></main-menu-item>
      </main-menu>
      <div class="layout-flexible font-align-left" v-if="routerVisible">
        <animated-router-view :organization-id="organizationId"></animated-router-view>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationBar from "@/components/notificationbar/NotificationBar";
import MainMenu from "@/components/mainmenu/MainMenu";
import MainMenuItem from "@/components/mainmenu/MainMenuItem";
import Background from "@/components/background/Background";
import OrganizationUtils from "@/assets/javascript/organization-utils";
import AnimatedRouterView from "@/components/animatedrouterview/AnimatedRouterView";
import httpapi from "@/assets/javascript/httpapi";

export default {
  name: "OrganizationContentManagement",
  mixins: [httpapi],
  components: {AnimatedRouterView, Background, MainMenuItem, MainMenu, NotificationBar},
  data() {
    return {
      organizationId: null,
      pickUp: false,
      currentTag: 'questionnaire-setting',
      routerVisible: true,
    }
  },
  methods: {
    handleOrganizationChanged: function () {
      this.routerVisible = false;
      this.$nextTick(() => {
        let organization = OrganizationUtils.$getOrganization();
        this.organizationId = organization ? organization.id : null;
        switch (this.currentTag) {
          case "questionnaire-setting":
            this.$router.replace({name: 'questionnaire_list'});
            break;
        }
        this.routerVisible = true;
      });
    },
    /**
     * 点击问卷管理
     */
    handleQuestionnaireMenuClick: function () {
      this.$router.push({name: 'questionnaire_list'});
    },

    /**
     * 点击打卡配置
     */
    handlePunchMenuClick: function () {
      this.$router.push({name: 'punch_list'})
    },

    /**
     * 点击食谱管理
     */
    handleCookeryBookMenuClick: function () {
      this.$router.push({name: 'cookery_book_list'});
    },

    /**
     * 点击报告模板
     */
    handleReportMenuClick: function () {
      this.$router.push({name: 'report_template_list'});
    },

    /**
     * 点击干预方案管理
     */
    handleSchemeMenuClick: function () {
      this.$router.push({name: 'scheme_list'});
    },

    /**
     * 点击系统设置
     */
    handleSystemSettingMenuClick() {
      this.$router.push({name: 'system_setting'});
    },

    /**
     * 点击返回日常管理
     */
    handleDailyManagementClick: function () {
      this.$router.replace({name: 'users'})
    },
  },
  mounted() {
    this.handleOrganizationChanged(OrganizationUtils.$getOrganization());
    window.eventBus.$on('organization_changed', this.handleOrganizationChanged);
  },
  destroyed() {
    window.eventBus.$off('organization_changed', this.handleOrganizationChanged);
  }
}
</script>

<style scoped>

.logo {
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 50%;
}


</style>